import axios from "axios"


class AuthServices {

    CreateAdminTheme(data) {
        return axios.post("/api/Microwebsite_Theme", data);
    }
    getAdminThemes() {
        return axios.get("/api/Microwebsite_Theme");
    }
    deleteAdminTheme(themeId) {
        return axios.delete("/api/Microwebsite_Theme/"+themeId);
    }
    putAdminTheme(theme) {
        return axios.put("/api/Microwebsite_Theme/" + theme.microwebsiteThemeId, theme);
    }
    getAdminTheme(themeId) {
        return axios.get("/api/Microwebsite_Theme/" + themeId);
    }

    postUserTheme(data) {
        return axios.post("/api/Microwebsite_Theme_User", data);
    }

    putUserTheme(theme) {
        return axios.put("/api/Microwebsite_Theme_User/" + theme.microwebsiteUserThemeId, theme);
    }
    getUserTheme(userId) {
        return axios.get("/api/Microwebsite_Theme_User/user/" + userId);
    }

    getUserThemeById(themeId) {
        return axios.get("/api/Microwebsite_Theme_User/" + themeId);
    }
}

export default new AuthServices();