import React, { useRef,useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import AuthServices from '../Services/AuthServices';
import { saveAs } from 'file-saver';
import { parseHTML } from 'jquery';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faDownload, faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { parse } from '@fortawesome/fontawesome-svg-core';

const ThemeViewer = (props) => {
    const [doc, setDoc] = useState(props.html)
   


    useEffect(() => {
        setDoc(props.html);
    });


    return (
        <div className="embed-responsive embed-responsive-4by3" style={{ }}>
            <iframe className="embed-responsive-item" srcDoc={doc}
                
            />
        </div>
    );
};

export default ThemeViewer;