import React, { useEffect, useState } from "react";
import AuthServices from "../../Services/AuthServices";
import ThemeViewer from "../ThemeViewer";
import { Container, Row, Col,Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { error } from "jquery";
import EditTheme from "./EditTheme";



const AdminDashboard = (props) => {
    const [themes, setThemes] = React.useState([]);
    const [mode, setMode] = React.useState('view');
    const [theme, setTheme] = React.useState({});


    useEffect(() => {
        if (themes.length == 0)
            GetTheme();
        
    });


    const GetTheme = () => {
        AuthServices.getAdminThemes().then(function (resp) {
            console.log(resp);
            if (resp.data.length > 0) {
                setThemes(resp.data);
            }
            
        }).catch(function (error) {
            console.log(error.response);
        });
    }

    const DeleteTheme = (theme) => {
        AuthServices.deleteAdminTheme(theme.microwebsiteThemeId).then(function (resp) {
            console.log(resp);
            GetTheme();
        }).catch(function (error) {
            console.log(error.response)
        });
    }

    const setEditTheme = async (theme) => {
        await setTheme(theme);
        setMode('edit');
        

    }


    return (
        

        <>
            {mode === 'view' && <>
                {
                    themes.length > 0 ?
                        <Container>
                            {
                                themes.map((theme, index) =>
                                    <>

                                        <Row key={theme.microwebsiteThemeId}>
                                            <Col xs={12} lg={{ size: 8, offset: 2 }} className="text-center">
                                                <ThemeViewer html={theme.html} />
                                                <span onClick={() => setEditTheme(theme)} className="btn btn-custom mb-2 mt-2 mr-2"  ><FontAwesomeIcon icon={faEdit} /> Edit</span>
                                                <span onClick={() => DeleteTheme(theme)} className="btn btn-danger mb-2 mt-2 mr-2 displaynone"  ><FontAwesomeIcon icon={faTrashAlt} /> Delete</span>
                                                <span onClick={() => window.location.href = '/admin/theme/preview?ID=' + theme.microwebsiteThemeId} className="btn btn-success mb-2 mt-2" ><FontAwesomeIcon icon={faEye} /> Preview</span>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                        </Container> :
                        <div>No Themes added</div>
                }
            </>}{mode==='edit'&&
                <>
                    <EditTheme theme={theme} />
                </>
            }
        </>


    )
}

export default AdminDashboard;