import React, { useRef, useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import AuthServices from '../../Services/AuthServices';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { parseHTML } from 'jquery';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faDownload, faEdit, faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { parse, text } from '@fortawesome/fontawesome-svg-core';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}


const EditUserTheme = (props) => {

    let query = useQuery();
    let userId = query.get('UserID');
    let userThemeId = query.get('ID');

    const emailEditorRef = useRef(null);
    const [doc, setDoc] = useState(null)
    const [theme, setTheme] = useState({});
    const [popUp, showPopUp] = useState(false);
    const [popUpMsg, setPopUpMsg] = useState('');
    const [spinnerShow, setSpinner] = useState('d-none');



    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            //console.log(data);
            const { design, html } = data;
            console.log('exportHtml', html);
            setDoc(html);
            console.log(doc);

            var content = html;
            // any kind of extension (.txt,.cpp,.cs,.bat)
            var filename = "template.html";

            var blob = new Blob([content], {
                type: "text/html"
            });

            saveAs(blob, filename);

            //console.log(parseHTML(html));
        });

    };



    const saveDesign = async (status) => {
        let self = this;
        emailEditorRef.current.editor.exportHtml(async (data) => {
            //console.log(data);
            const { design, html } = data;
            setSpinner('');
            if (status == 'active') {
                await AuthServices.getUserTheme(userId).then(async function (resp) {
                    console.log(resp);
                    for (let i = 0; i < resp.data.length; i++) {
                        resp.data[i].status = 'draft';
                        await AuthServices.putUserTheme(resp.data[i]).then(function (res) {
                            console.log(res);
                        }).catch(function (error) {
                            console.log(error.res)
                        });

                    }
                }).catch(function (error) {
                    console.log(error.response);
                });

                setPopUpMsg("Theme Saved Successfully!!!");

            }
            else {
                setPopUpMsg("Theme Drafted Successfully!!!");
            }

            theme.status = status;
            theme.html = html;
            theme.json = JSON.stringify(design.body);
            console.log(theme);
            AuthServices.putUserTheme(theme).then(function (resp) {
                console.log(resp);
                //window.location.href = '/user/themes?UserID=' + userId;
                setSpinner('d-none');
                showPopUp(true);
            }).catch(function (error) {
                console.log(error.response);
            });
        });

    }

    const onLoad = async () => {


        AuthServices.getUserThemeById(userThemeId).then(async function (resp) {
            console.log(resp);
            emailEditorRef.current.editor.loadDesign({ body: JSON.parse(resp.data.json) });
            await setTheme(resp.data);
        }).catch(function (error) {
            console.log(error.response);
        });

    };








    return (
        <div className="text-center">
            <Spinner className={spinnerShow} style={{ width: '3rem', height: '3rem' }} type="grow" />
            <Alert color="success" className="text-center savesuccessfully" isOpen={popUp}  >
                {popUpMsg}
            </Alert>

            <div className="text-left">
                <button className="btn btn-success displaynone" onClick={exportHtml}><FontAwesomeIcon icon={faDownload} /> Download HTML</button>

                <button className="btn btn-custom btn-xs" onClick={() => saveDesign("active")}><FontAwesomeIcon icon={faSave} /> Save Design</button>
                <button className="btn btn-info btn-xs" onClick={() => saveDesign("draft")}><FontAwesomeIcon icon={faEdit} /> Draft</button>
            </div>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
            />

        </div>
    );
};

export default EditUserTheme;