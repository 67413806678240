import React, { useEffect, useState } from "react";
import AuthServices from "../../Services/AuthServices";
import ThemeViewer from "../ThemeViewer";
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { error } from "jquery";
import EditUserTheme from "./EditUserTheme";


function useQuery() {
    return new URLSearchParams(useLocation().search)
}


const UserThemes = (props) => {

    let query = useQuery();
    let userId = query.get('UserID');
    console.log(userId);

    const [themes, setThemes] = React.useState([]);
    const [mode, setMode] = React.useState('view');
    const [theme, setTheme] = React.useState({});


    useEffect(() => {
        if (themes.length == 0)
            GetTheme();

    });



    const GetTheme = () => {
        AuthServices.getUserTheme(userId).then(function (resp) {
            console.log(resp);
            if (resp.data.length > 0) {
                setThemes(resp.data);
            }

        }).catch(function (error) {
            console.log(error.response);
        });
    }

    //const DeleteTheme = (theme) => {
    //    AuthServices.deleteAdminTheme(theme.microwebsiteThemeId).then(function (resp) {
    //        console.log(resp);
    //        GetTheme();
    //    }).catch(function (error) {
    //        console.log(error.response)
    //    });
    //}

    const setEditTheme = async (theme) => {
        window.location.href = '/user/theme/edit?ID=' + theme.microwebsiteUserThemeId + '&UserID=' + userId


    }


    return (


        <>
            {mode === 'view' && <>
                {
                    themes.length > 0 ?
                        <Container>
                            {
                                themes.map((theme, index) =>
                                    <>

                                        <Row className="mt-2" key={theme.microwebsiteThemeId}>
                                            <Col xs={12} lg={{ size: 8, offset: 2 }} className="text-center">
                                                <div className="text-left align-middle">Status: {theme.status} <span onClick={() => setEditTheme(theme)} className="btn btn-sm btn-custom mt-2 float-right" ><FontAwesomeIcon icon={faEdit} /> Edit</span>
                                                    <span onClick={() => window.location.href = '/user/theme/preview?ID=' + theme.microwebsiteUserThemeId} className="btn btn-sm btn-success mt-2 float-right" ><FontAwesomeIcon icon={faEye} /> Preview</span> </div>
                                                <ThemeViewer html={theme.html} />
                                                
                                                
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                        </Container> :
                        <div>No Themes added</div>
                }
            </>}{mode === 'edit' &&
                <>
                    <EditUserTheme theme={theme} />
                </>
            }
        </>


    )
}

export default UserThemes;