import React, { useRef, useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import AuthServices from '../../Services/AuthServices';
import { saveAs } from 'file-saver';
import { parseHTML } from 'jquery';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faDownload, faEdit, faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { parse, text } from '@fortawesome/fontawesome-svg-core';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const EditTemplate = (props) => {

    let query = useQuery();
    let adminThemeId = query.get('ID');
    let userId = query.get('UserID');

    const emailEditorRef = useRef(null);
    const [doc, setDoc] = useState(null)
    const [theme, setTheme] = useState(props.theme);

    const [popUp, showPopUp] = useState(false);
    const [popUpMsg, setPopUpMsg] = useState('');
    const [spinnerShow, setSpinner] = useState('d-none');




    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            //console.log(data);
            const { design, html } = data;
            console.log('exportHtml', html);
            setDoc(html);
            console.log(doc);

            var content = html;

            var filename = "template.html";

            var blob = new Blob([content], {
                type: "text/html"
            });

            saveAs(blob, filename);

            //console.log(parseHTML(html));
        });

    };




    const saveDesign = async (status) => {
        let self = this;
        emailEditorRef.current.editor.exportHtml(async (data) => {
            //console.log(data);
            setSpinner('');

            const { design, html } = data;

            if (status == 'active') {
                await AuthServices.getUserTheme(userId).then(async function (resp) {
                    console.log(resp);
                    for (let i = 0; i < resp.data.length; i++) {
                        resp.data[i].status = 'draft';
                        await AuthServices.putUserTheme(resp.data[i]).then(function (res) {
                            console.log(res);
                        }).catch(function (error) {
                            console.log(error.res)
                        });

                    }
                }).catch(function (error) {
                    console.log(error.response);
                });



                setPopUpMsg("Theme Saved Successfully!");

            }
            else {
                setPopUpMsg("Theme Drafted Successfully!");
            }

            let userTheme = {
                "profileId": parseInt(userId),
                "status": status,
                "html": html,
                "json": JSON.stringify(design.body),
                "microwebsiteThemeId": parseInt(adminThemeId)
            }
            console.log(userTheme);
            AuthServices.postUserTheme(userTheme).then(function (resp) {
                console.log(resp);
                setSpinner('d-none');
                showPopUp(true);
                //window.location.href = '/user/themes/?UserID=' + userId;
            }).catch(function (error) {
                console.log(error.response);
            });
        });

    }

    const onLoad = async () => {

        AuthServices.getAdminTheme(adminThemeId).then(function (resp) {
            console.log(resp);
            let data = resp.data;
            setTheme(data);
            emailEditorRef.current.editor.loadDesign({ body: JSON.parse(data.json) });

        }).catch(function (error) {
            console.log(error.response)

        });
        //if (props.theme !== {}) {
        //    console.log(props.theme.json);
        //    emailEditorRef.current.editor.loadDesign({ body: JSON.parse(props.theme.json) });

        //}




    };









    return (
        <div className="text-center">
            

            <Spinner className={spinnerShow} style={{ width: '3rem', height: '3rem' }} type="grow" />
                <Alert color="success" className="text-center savesuccessfully" isOpen={popUp}  >
                {popUpMsg}
            </Alert>
          
            <div className="text-left">
               
              

                <button className="btn btn-success displaynone" onClick={exportHtml}><FontAwesomeIcon icon={faDownload} /> Download HTML</button>

                <button className="btn btn-custom btn-xs" onClick={() => saveDesign("active")}><FontAwesomeIcon icon={faSave} /> Save Design</button>
                <button className="btn btn-info btn-xs" onClick={() => saveDesign("draft")}><FontAwesomeIcon icon={faEdit} /> Draft</button>


            </div>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
            />

        </div>
    );
};

export default EditTemplate;