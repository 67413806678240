import React, { useEffect, useState } from "react";
import AuthServices from "../../Services/AuthServices";
import ThemeViewer from "../ThemeViewer";
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { error } from "jquery";



const Templates = (props) => {
    const [themes, setThemes] = React.useState([]);
    const [theme, setTheme] = React.useState({});
    


    useEffect(() => {
        if (themes.length == 0)
            GetTheme();

    });


    const GetTheme = () => {
        AuthServices.getAdminThemes().then(function (resp) {
            console.log(resp);
            if (resp.data.length > 0) {
                setThemes(resp.data);
            }

        }).catch(function (error) {
            console.log(error.response);
        });
    }

    const selectTheme = (theme) => {
        window.location.href = '/templates/editTemplate?ID=' + theme.microwebsiteThemeId + '&UserID=1';
    }

   


    return (


        <>
           
                {
                    themes.length > 0 ?
                        <Container>
                            {
                                themes.map((theme, index) =>
                                    <>

                                        <Row key={theme.microwebsiteThemeId}>
                                            <Col xs={12} lg={{ size: 8, offset: 2 }} className="text-center">

                                                <ThemeViewer html={theme.html} />
                                                <span onClick={() => selectTheme(theme)} className="btn btn-custom mt-2 mb-2 mr-2"  >Select</span>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                        </Container> :
                        <div>No Themes available</div>
                }
            
        </>


    )
}

export default Templates;