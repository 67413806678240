import React, { useRef, useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import AuthServices from '../../Services/AuthServices';
import { saveAs } from 'file-saver';
import { parseHTML } from 'jquery';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faDownload, faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { parse, text } from '@fortawesome/fontawesome-svg-core';

const EditTheme = (props) => {
    const emailEditorRef = useRef(null);
    const [doc, setDoc] = useState(null)
    const [theme, setTheme] = useState(props.theme);
    


    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            //console.log(data);
            const { design, html } = data;
            console.log('exportHtml', html);
            setDoc(html);
            console.log(doc);

            var content = html;
            // any kind of extension (.txt,.cpp,.cs,.bat)
            var filename = "template.html";

            var blob = new Blob([content], {
                type: "text/html"
            });

            saveAs(blob, filename);

            //console.log(parseHTML(html));
        });

    };



    const saveDesign = () => {
        let self = this;
        emailEditorRef.current.editor.exportHtml((data) => {
            //console.log(data);
            const { design, html } = data;
            props.theme.json = JSON.stringify(design.body);
            props.theme.html = html;
            console.log(props.theme);
            AuthServices.putAdminTheme(props.theme).then(function (resp) {
                console.log(resp);
                window.location.reload(false);
            }).catch(function (error) {
                console.log(error.response);
            });
        });

    }

    const onLoad = async () => {
        
        
        if (props.theme !== {}) {
            console.log(props.theme.json);
            emailEditorRef.current.editor.loadDesign({ body: JSON.parse(props.theme.json) });
            
        }




    };





    return (
        <div>
            <div>
                <button className="btn btn-success" onClick={exportHtml}><FontAwesomeIcon icon={faDownload} /> Download HTML</button> <button className="btn btn-custom" onClick={saveDesign}><FontAwesomeIcon icon={faSave} /> Save Design</button>
            </div>


            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
            />

        </div>
    );
};

export default EditTheme;