import React, { Component, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import './Styles.css'


import './custom.css';
import CreateTheme from './components/Admin/CreateTheme';
import AdminDashboard from './components/Admin/AdminDashboard';
import Templates from './components/Templates/Templates';
import EditTemplate from './components/Templates/EditTemplate';
import UserThemes from './components/User/UserThemes';
import EditUserTheme from './components/User/EditUserTheme';
import ThemePreview from './components/User/ThemePreview';
import AdminThemePreview from './components/Admin/AdminThemePreview';



export default class App extends Component {
    static displayName = App.name;




    render() {

        return (

            <Switch>
                <Route exact path="/admin/themes" component={AdminDashboard} />
                <Route exact path="/admin/create" component={CreateTheme} />
                <Route exact path="/templates" component={Templates} />
                <Route exact path="/templates/editTemplate" component={EditTemplate} />
                <Route exact path="/user/themes" component={UserThemes} />
                <Route exact path="/user/theme/edit" component={EditUserTheme} />
                <Route exact path="/user/theme/preview" component={ThemePreview} />
                <Route exact path="/admin/theme/preview" component={AdminThemePreview} />
                <Redirect to="/templates"/>
            </Switch>

        );
    }
}
