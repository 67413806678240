import React, { useRef, useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import AuthServices from '../../Services/AuthServices';
import { useLocation } from 'react-router-dom';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faDownload, faEdit, faLock, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { parse, text } from '@fortawesome/fontawesome-svg-core';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const ThemePreview = (props) => {

    let query = useQuery();
    let userThemeId = query.get('ID');

    const emailEditorRef = useRef(null);
    const [doc, setDoc] = useState(null)
    const [theme, setTheme] = useState({});

    const onLoad = async () => {


        AuthServices.getUserThemeById(userThemeId).then(async function (resp) {
            console.log(resp);
            await setDoc(resp.data.html);
            //emailEditorRef.current.editor.loadDesign({ body: JSON.parse(resp.data.json) });

            await setTheme(resp.data);
            
        }).catch(function (error) {
            console.log(error.response);
        });

    };


    return (
        <>
            <div class="d-none">
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                />
            </div>
            <div>
                <div className="embed-responsive embed-responsive-16by9" style={{ height:"100vh" }}>
                    <iframe className="embed-responsive-item" srcDoc={doc}

                    />
                </div>
            </div>
        </>
        )
}

export default ThemePreview;